import React from 'react';
import { Container } from 'semantic-ui-react';
import content from '../../config/content';

const styles = {
  textStyle: {
    color: 'white',
    textAlign: 'center',
    margin: '5rem',
  } as React.CSSProperties,
};

const Footer = () => {
  return (
    <Container>
      <div style={styles.textStyle}>
        <div>
          Developed by{' '}
          <a
            href={'https://ebaengineering.com/services/geospatial/'}
            rel="noopener noreferrer"
            target="_blank"
          >
            geographIT
          </a>
        </div>
        For support or questions please contact us:{' '}
        <a href={`mailto:${content.link.content}`}>
          <i>{content.email.header}</i>
        </a>
      </div>
    </Container>
  );
};

export default Footer;
