import React, { ReactElement } from 'react';

import { FetchProvider } from './context/Fetch';

import Footer from './ui/Footer';
import Nav from './ui/Nav';

const Theme = ({ children }: { children: ReactElement }) => {
  return (
    <FetchProvider>
      <>
        <Nav />
        <div>{children}</div>
        <Footer />
      </>
    </FetchProvider>
  );
};

export default Theme;
