const content = {
  title: 'NG 9-1-1 Portal',
  validation: {
    header: 'Submit your data for validation',
    messages: {
      error: {
        message: 'There was an error with your upload. Please try again.',
      },
      processing: {
        message: 'Upload Complete. Running analysis....',
      },
      uploading: {
        message: 'Your file is uploading. Please wait.',
      },
    },
    subheader:
      'First browse for your zipped GDB, then enter your GDB name, and upload your file',
  },
  welcome: {
    header: 'Welcome to the NG 9-1-1 GIS Data Conversion Portal',
    welcomeItems: {
      content:
        "This portal will take a county's NG 9-1-1 required GIS layers and convert them from their native schema to the NENA NG 9-1-1 stadardized schema.",

      content1: 'Outputs provided include : ',

      content2: 'The county dataset in the NENA standard',

      content3:
        'QA/QC feature classes showing data items that are flagged as having issues according to the NENA standard.',

      content4: ' A multi-county output of all of the data in the Portal.',

      content5: 'To start using this Portal, click on Data Conversion above.',
    },
  },
  email: {
    header: 'support@geographIT.io',
  },
  link: {
    content: 'support@geographIT.io',
  },
  helpPage: {
    header: 'NG911 Help Document',
    HelpPageitem: {
      content1: 'Sign In and Home Page',
      content2: 'NG 9-1-1 Home Page',
      content3: 'Data Conversion ',
      content4: 'Data Download',
      content5: 'Further Information/Links',
    },
  },
  HelpPageContent1: {
    Header1: 'Username:',
    Header2: 'Password:',
    HelpAccordItems: {
      content1: 'Your username is the email address that you provide to EBA',
      content2:
        'If you are signing in for the first time, use the password provided to you by EBA',
      content3:
        'After signing in for the first time you will be prompted to change your password',
      content4:
        'Password requirements include atleast 6 characters, requiring atleast one uppercase, one lowercase, one number, and one special character (i.e. $#@&*)',
      content5:
        'To change your password, select "reset password" on the sign in page, enter your username, and select "send code". The code will be sent to your corresponding email, and allows you to rest the password when entered',
      content6:
        'After signing in, you will be redirected to the NG911 Portal Home Page where it gives you an overview off the whole process, from inputting your GIS data, converting it to NENA standard, and downloading the new data',
    },
  },
  HelpPageContent3: {
    HelpAccordItems: {
      content1:
        'Navigate to the tab Data Conversion to begin the process of converting the data from its native schema to the NENA standard schema',
      content2:
        'If your data is not in a geodatabase, download the template at the bottom of the page',
      content3: 'Insert your data and zip up the file geodatabase',
      content4:
        'Once you have done the previous steps you can upload your data',
      content5:
        'As your data is uploading, it will go through a validation process to analyze any possible errors',
      content6:
        'Once the analysis is complete, it will display the validation results table with a list of feature classes within your geodatabase along with the status and notes associated with analysis conducted',
      content7:
        'After your gdb is validated, you can submit the ETL Job by clicking the Run ETL button on the page',
      content8: 'You will receive an email when the ETL is complete.',
      content9:
        'The amount of time it takes the ETL to complete may vary based on the size of the gdb',
    },
  },
  HelpPageContent4: {
    content1:
      '	Upon receiving the email stating the ETL is complete you may click the tab Data Download, which is in the top right-hand corner of the page',
    content2:
      ' Once in the Data Download page you will find your data separated into a few geodatabases. Provided is the new county dataset in the NENA standard, QA/QC feature classes, and a multi-county output of all the data in the NG 9-1-1 Portal',
    content3:
      'The QA/QC feature class will have data items that are flagged issues according to the NENA standard',
  },
  HelpPageContent5: {
    content1: 'For more information, please visit the links below!',
    link1: 'https://www.nena.org/page/NG911GISDataModel',
    link2:
      'https://www.pema.pa.gov/911-Program/Partners/NG911-GIS/Pages/default.aspx',
    link3:
      'https://pema.maps.arcgis.com/home/item.html?id=fd8ec0be4f874066b3ef73b4a3a2aeaf',
  },
  HelpPageContent6: {
    Header1: 'Steps to use the Portal',
    HelpAccordItems: {
      content1: 'Login access',
      content1Sub1: 'Decide who will be your county’s portal manager',
      content1Sub2: 'Assign duties',
      content1Sub3: 'Register and confirm your user access',
      content2: 'Review your Template GDB',
      content2Sub1:
        'Confirm that the data you are submitting for upload matches the geodatabase name, layer names, and field names of the blank Template GDB',
      content3: 'Review your county output GDB',
      content3Sub1:
        'Confirm that the number of features that you uploaded match the original gdb',
      content4: 'Review your county output QAQC GDB',
    },
  },
  HelpPageContent7: {
    Header1: 'FAQ',
    HelpAccordItems: {
      content1:
        'If data types change in my source data, can I still run the validation process?',
      content1Sub1:
        'Any data types that change in the county source data will cause the validation to fail.  A data type change is considered a schema difference when validating source data to the portal’s configuration.  Please contact the geographIT team for these changes to be made.',
      content2:
        'If feature class names change in my source data, can I still run the validation process?',
      content2Sub1:
        'Any Feature Class names that change in the county source data will cause the validation to fail.  A name change is considered a schema difference when validating source data to the portal’s configuration.  Please contact the geographIT team for these changes to be made.',
      content3: 'I never recieved an email with my validation results?',
      content3Sub1:
        'Validation results can take time, especially if your county has a large dataset. However, if you fail to recieve your validation results, please contact the geographIT team for futher support.',
    },
  },
  Validate: {
    header1: 'Data Validation​',
    header2: 'Once data is validated, run the ETL process. ​',
    ValidateHelpInfo: {
      content2:
        'Upload zipped file geodatabase (.gdb) of data to be processed.​',
      content3: 'Data will be validated against expected schema.​',
      content4: 'A template .FGDB is available for download below.',
      content6:
        'You will be notified via email when the process is complete, and the data is ready for downloading.​',
    },
  },
};

export default content;
