import React from 'react';
import styled from 'styled-components';

import WelcomeInfoList from './WelcomeInfoList';

import hero from '../../assets/hero2.jpg';

const HeroBack = styled.div`
  background: url(${hero}) no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;
`;

const HeaderDiv = styled.div`
  flex-basis: 33.33%;
  padding: 3rem 1.5rem 3rem 3rem;
`;

const HeaderH1 = styled.h1`
  font-family: 'Raleway', sans-serif;
  color: ${props => (props.inverted ? 'white' : 'inherit')};
  font-size: 6rem;
`;

const HeaderH2 = styled.h2`
  font-family: 'Raleway', sans-serif;
  color: ${props => (props.inverted ? 'white' : 'inherit')};
  margin-top: 1rem;
  font-size: 5rem;
`;

const InfoDiv = styled.div`
  flex-basis: 66.66%;
  padding: 3rem 3rem 3rem 1.5rem;
`;

const WelcomeHero = ({ inverted }: { inverted: boolean }) => {
  return (
    <HeroBack>
      <HeaderDiv>
        <HeaderH1 inverted={inverted}>NG 9-1-1</HeaderH1>
        <HeaderH2 inverted={inverted}>GIS Data Conversion Portal</HeaderH2>
      </HeaderDiv>
      <InfoDiv>
        <WelcomeInfoList inverted={inverted} />
      </InfoDiv>
    </HeroBack>
  );
};

export default WelcomeHero;
