import _ from 'lodash';
import * as React from 'react';

import { sentryCapture } from '../config/sentry';
import formatValidation from '../utils/format-validation';
import upload, { extractFileInfo } from '../utils/uploadS3';

const useFetchValidate = (fetch, setUploadProgress) => {
  // state
  const [status, setStatus] = React.useState({ status: 'ready', info: null });
  const [formValidation, setFormValidation] = React.useState({
    chooseFile: null,
  });

  const [fileToUpload, setFileToUpload] = React.useState(null);
  const [validationResults, setValidationResults] = React.useState(null);
  const [runUID, setRunUID] = React.useState(null);

  // functions
  const handleFileChange = e => {
    const selected = extractFileInfo(e);

    if (selected) {
      if (!_.endsWith(selected.name, '.zip')) {
        setFormValidation({
          ...formValidation,
          chooseFile: 'File must end in .zip',
        });
      } else {
        setStatus({ status: 'ready', info: null });
        setValidationResults(null);
        setRunUID(null);
        setFormValidation({ chooseFile: null });
        setFileToUpload(selected);
      }
    }
  };

  const handleUploadError = e => {
    // tslint:disable-next-line
    console.error(e);
    setStatus({ status: 'error', info: e });
  };

  const handleUploadSuccess = async (e: any) => {
    setUploadProgress(null);
    setStatus({ status: 'working', info: 'processing' });
    handleRunValidation(e);
  };

  const handleRunValidation = async (uploadEvt: object) => {
    try {
      const resp = await fetch.RunValidation({
        s3Response: uploadEvt,
      });
      setStatus({ status: 'success', info: null });
      setFileToUpload(null);
      setValidationResults(formatValidation(resp.result.data));
      setRunUID(resp.uid);
    } catch (error) {
      sentryCapture(error);
      // tslint:disable-next-line
      console.error(error);
      setStatus({ status: 'error', info: null });
    }
  };

  const handleUpload = async () => {
    setStatus({ status: 'working', info: 'uploading' });
    setFormValidation({ chooseFile: null });
    try {
      const result = await upload(fileToUpload, setUploadProgress);
      handleUploadSuccess(result);
    } catch (error) {
      sentryCapture(error);
      handleUploadError(error);
    }
  };

  const resetAnalysis = () => {
    setStatus({ status: 'ready', info: null });
    setValidationResults(null);
    setRunUID(null);
  };

  return {
    fileToUpload,
    formValidation,
    handleFileChange,
    handleUpload,
    status,
    validationResults,
    runUID,
    resetAnalysis,
    setValidationResults,
    setRunUID,
  };
};

export default useFetchValidate;
