/* tslint:disable:no-console */
import React from 'react';
import { Progress } from 'semantic-ui-react';

const ProgessBar = ({
  progress,
}: {
  progress: { loaded: number; total: number } | null;
}) => {
  if (!progress) {
    return null;
  }

  return (
    <Progress
      value={(progress.loaded / progress.total) * 100}
      style={{ marginBottom: '-1.28571429rem' }}
      total={100}
      attached="top"
      color="blue"
    />
  );
};

export default ProgessBar;
