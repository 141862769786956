import React from 'react';
import {
  Button,
  Grid,
  Header,
  Icon,
  Message,
  Segment,
} from 'semantic-ui-react';
import styled from 'styled-components';
import SentryFeedback from './SentryFeedback';

import { ThemeContext } from '../context/Theme';

const BtnWrap = styled.div`
  justify-content: center;
  display: flex;
  padding-top: 1rem;
`;

const RunETLMsg = ({
  positive,
  error,
  content,
  dismiss,
}: {
  positive?: boolean;
  error?: boolean;
  content: string;
  dismiss: () => void;
}) => {
  return (
    <Message icon positive={positive} error={error} onDismiss={dismiss}>
      <Icon name="send" />
      <Message.Content>
        <Message.Header>
          {error ? 'ETL Error' : 'ETL Job Submitted'}
        </Message.Header>
        {content}
      </Message.Content>
      <SentryFeedback />
    </Message>
  );
};

const RunETL = ({
  results,
  uid,
  status,
  handleClick,
  handleReset,
}: {
  results: { status: string };
  uid: string | null;
  status: { status: string };
  handleClick: any;
  handleReset: any;
}) => {
  // context
  const { darkMode } = React.useContext(ThemeContext);

  const handleRunETL = () => {
    handleClick(uid);
    // handleReset('clear-analysis');
  };

  const handleDismiss = () => {
    handleReset('clear-analysis');
  };

  // render
  if (!results) {
    return null;
  }

  if (!uid && results.status !== 'valid') {
    return null;
  }

  if (results.status !== 'valid') {
    return null;
  }

  const showMsg = status.status === 'success' || status.status === 'error';
  const showBtn = status.status === 'ready' || status.status === 'loading';
  return (
    <Grid.Row columns={1}>
      <Grid.Column>
        <Segment inverted={darkMode} padded="very">
          <Header
            inverted={darkMode}
            content="Submit ETL Job"
            subheader="Run the ETL process on the validated GDB. Please allow some time for the data to be processed. You will receive an email upon completion."
          />
          {showMsg && (
            <RunETLMsg
              positive={status.status === 'success'}
              error={status.status === 'error'}
              content="Your ETL has been submitted! Please await an email notification when the process has completed."
              dismiss={handleDismiss}
            />
          )}
          {showBtn && (
            <BtnWrap>
              <Button
                positive
                size="massive"
                onClick={() => {
                  handleRunETL();
                }}
                loading={status.status === 'loading'}
              >
                Run ETL
              </Button>
            </BtnWrap>
          )}
        </Segment>
      </Grid.Column>
    </Grid.Row>
  );
};

export default RunETL;
