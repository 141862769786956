import { Storage } from 'aws-amplify';

export const listObjects = () => {
  return new Promise((resolve, reject) => {
    Storage.list('', { level: 'private' })
      .then(result => resolve(result))
      .catch(err => reject(err));
  });
};

export const downloadObject = (key: string) => {
  return new Promise((resolve, reject) => {
    Storage.get(key, { level: 'private' })
      .then(downloadUrl => resolve(downloadUrl))
      .catch(err => reject(err));
  });
};

export const downloadTemplate = (key: string) => {
  return new Promise((resolve, reject) => {
    Storage.get(`templates/${key}`, { level: 'public' })
      .then(downloadUrl => resolve(downloadUrl))
      .catch(err => reject(err));
  });
};
