import React from 'react';
import { Segment } from 'semantic-ui-react';

const TemplateDownload = ({
  url,
  inverted,
}: {
  url: string;
  inverted: boolean;
}) => {
  return (
    <Segment inverted={inverted}>
      <h5 style={{ textAlign: 'center' }}>
        Download your template GDB and insert your data!
      </h5>
      <p style={{ textAlign: 'center', marginTop: '1rem' }}>
        <a href={url}>Download Your Template GDB</a>
      </p>
    </Segment>
  );
};

export default TemplateDownload;
