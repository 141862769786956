import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

const Loading = ({ loading }: { loading: boolean }) => {
  return (
    <Dimmer active={loading} page>
      <Loader />
    </Dimmer>
  );
};

export default Loading;
