import React from 'react';
import { Header, Segment } from 'semantic-ui-react';

import { ThemeContext } from '../components/context/Theme';

const Settings = () => {
  const { darkMode } = React.useContext(ThemeContext);

  return (
    <Segment inverted={darkMode}>
      <Header inverted={darkMode}>update settings</Header>
    </Segment>
  );
};

export default Settings;
