import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import gaInit from './config/ga';
import { sentryInit } from './config/sentry';
import * as serviceWorker from './serviceWorker';

import './index.css';
// init Google Analytics
gaInit();

// init Sentry
sentryInit();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
