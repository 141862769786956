import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Header, List, Segment } from 'semantic-ui-react';
import styled from 'styled-components';

import content from '../../config/content';

// TODO: make array in config
const welcomeBullets = [
  content.welcome.welcomeItems.content2,
  content.welcome.welcomeItems.content3,
  content.welcome.welcomeItems.content4,
];

const CallToAction = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
`;

const WelcomeInfoList = ({ inverted }: { inverted: boolean }) => {
  const history = useHistory();
  return (
    <Segment inverted={inverted}>
      <Header
        as="h2"
        inverted={inverted}
        content={content.welcome.header}
        subheader="Please review the instructions below"
      />
      <Header as="h4">{content.welcome.welcomeItems.content}</Header>
      <Header as="h4">{content.welcome.welcomeItems.content1}</Header>
      <List inverted={inverted} bulleted>
        {_.map(welcomeBullets, (v, k) => {
          return <List.Item key={k}>{v}</List.Item>;
        })}
      </List>
      <CallToAction>
        <Button
          primary
          size="large"
          style={{ padding: '1rem 3rem', margin: 0 }}
          onClick={() => history.push('/data-conversion')}
        >
          Get Started
        </Button>
      </CallToAction>
    </Segment>
  );
};

export default WelcomeInfoList;
