import * as Sentry from '@sentry/browser';
import { captureErrorGA } from './ga';

export const sentryInit = () => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DNS,
      environment: process.env.REACT_APP_APP_URL,
      debug: process.env.NODE_ENV !== 'production',
    });
  }
};

export const sentryContext = user => {
  Sentry.configureScope(scope => {
    scope.setUser({ id: user.sub, username: user.username, email: user.email });
  });
};

export const sentryCapture = error => {
  Sentry.captureException(error);
  captureErrorGA(error);
};
