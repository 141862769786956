import React from 'react';
import { Container, Header, Segment } from 'semantic-ui-react';

import { ThemeContext } from '../components/context/Theme';
import useFetchDownload from '../hooks/useFetchDownload';

import Loading from '../components/ui/Loading';
import Table from '../components/ui/S3ItemsTable';

const Download = () => {
  // context
  const { darkMode } = React.useContext(ThemeContext);

  // hooks
  const { status, objects, downloadUrl, downloadFile } = useFetchDownload();
  return (
    <Container>
      <Loading loading={status.status === 'loading'} />
      <Segment inverted={darkMode}>
        {objects && (
          <>
            <Header
              inverted={darkMode}
              content="Download Your Data"
              subheader="Click on file name to download"
            />
            <Table
              objects={objects}
              darkMode={darkMode}
              downloadUrl={downloadUrl}
              handleDownloadFile={downloadFile}
            />
          </>
        )}
      </Segment>
    </Container>
  );
};

export default Download;
