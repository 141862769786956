import * as React from 'react';

interface IContext {
  darkMode: boolean;
  setDarkMode: any;
}

export const ThemeContext = React.createContext<IContext | null>(null);

export const ThemeProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  // state
  const [darkMode, setDarkMode] = React.useState(true);

  React.useEffect(() => {
    if (!darkMode) {
      document.body.classList.add('light-mode');
    }
  }, [darkMode]);

  // render
  return (
    <ThemeContext.Provider
      value={{
        darkMode,
        setDarkMode,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
