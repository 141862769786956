import { Auth } from 'aws-amplify';
import React from 'react';
import { __RouterContext } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';

import { FetchContext } from '../context/Fetch';
import { ThemeContext } from '../context/Theme';

import ProgessBar from './ProgessBar';

import content from '../../config/content';

const menuItems = [
  { name: 'data-conversion' },
  // { name: 'ETL' },
  { name: 'data-download' },

  { name: 'help' },
  // { name: 'settings' },
];

const reducer = (state: any, action: { active: any }) => {
  return { active: action.active };
};

const Nav = () => {
  // context
  const { location, history } = React.useContext(__RouterContext);
  const { uploadProgress } = React.useContext(FetchContext);
  const { darkMode } = React.useContext(ThemeContext);

  // state
  const [state, dispatch] = React.useReducer(reducer, {
    active:
      location.pathname === '/'
        ? location.pathname
        : location.pathname.substr(1),
  });

  // functions
  const handleNav = (to: string) => {
    history.push(to);
    dispatch({ active: to });
  };

  const handleSignOut = () => {
    Auth.signOut();
  };

  // render
  return (
    <>
      <ProgessBar progress={uploadProgress} />
      <Menu inverted={darkMode} stackable>
        <Menu.Item
          header
          active={state.active === '/'}
          onClick={() => handleNav('/')}
        >
          <Icon name="home" size="large" />
          {content.title}
        </Menu.Item>
        <Menu.Menu position="right">
          {menuItems.map(m => {
            return (
              <Menu.Item
                key={m.name}
                name={m.name}
                active={state.active === m.name}
                onClick={() => handleNav(m.name)}
              />
            );
          })}

          <Menu.Item name="logout" onClick={handleSignOut} />
        </Menu.Menu>
      </Menu>
    </>
  );
};

export default Nav;
