import * as Sentry from '@sentry/browser';
import React from 'react';
import { Button } from 'semantic-ui-react';

const SentryFeedback = () => {
  return (
    <Button onClick={() => Sentry.showReportDialog()}>Report Feedback</Button>
  );
};

export default SentryFeedback;
