import { Storage } from 'aws-amplify';
import { sentryCapture } from '../config/sentry';

export const extractFileInfo = (e: {
  target: { files: Array<{ name: string; type: string }> };
}): { contentType: string; file: object; name: string } | null => {
  try {
    if (e.target.files.length <= 0) {
      return null;
    }

    return {
      contentType: e.target.files[0].type,
      file: e.target.files[0],
      name: e.target.files[0].name,
    };
  } catch (error) {
    sentryCapture(error);
    return null;
  }
};

const upload = (
  fileToUpload: { file: any; name: any; contentType: any },
  pCB: (arg0: any) => void,
) => {
  const { file, name, contentType } = fileToUpload;
  let info = null;
  // Storage will prepend the users id to the s3 path
  return new Promise((resolve, reject) => {
    Storage.put(`${name}`, file, {
      contentType,
      level: 'private',
      progressCallback(p: any) {
        info = p;
        pCB(p);
      },
    })
      .then(result => resolve({ result, info }))
      .catch(err => reject(err));
  });
};

export default upload;
