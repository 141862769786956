import { Auth } from 'aws-amplify';
import * as React from 'react';

import { Fetch, getUser, IUser } from '../../utils/fetch';

import { sentryContext } from '../../config/sentry';

import { setGAUser } from '../../config/ga';

interface IContext {
  fetch: object;
  user: IUser;
  uploadProgress: { loaded: number; total: number } | null;
  setUploadProgress: React.Dispatch<any>;
}

export const FetchContext = React.createContext<IContext | null>(null);

export const FetchProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  // state
  const [fetch, setFetch] = React.useState(null);
  const [user, setUser] = React.useState({
    group: '',
    username: '',
    email: '',
  });
  const [uploadProgress, setUploadProgress] = React.useState(null);

  // functions
  const getSession = async () => {
    const session = await Auth.currentSession();
    const userObject = getUser(session);
    const initFetch = new Fetch(session, userObject);
    setUser(userObject);
    sentryContext(userObject);
    setGAUser(user);
    setFetch(initFetch);
  };

  // lifecycle
  React.useEffect(() => {
    getSession();
    // eslint-disable-next-line
  }, []);

  // render
  return (
    <FetchContext.Provider
      value={{
        fetch,
        user,
        uploadProgress,
        setUploadProgress,
      }}
    >
      {children}
    </FetchContext.Provider>
  );
};
