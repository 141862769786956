import React from 'react';
import { Container, Grid, Header, Segment } from 'semantic-ui-react';

import { FetchContext } from '../components/context/Fetch';
import { ThemeContext } from '../components/context/Theme';

import useFetchETL from '../hooks/useFetchETL';
import useFetchValidate from '../hooks/useFetchValidate';

import LoadingMessage from '../components/ui/LoadingMessage';
import RunETL from '../components/ui/RunETL';
import TemplateDownload from '../components/ui/TemplateDownload';
import ValidationForm from '../components/ui/ValidationForm';
import ValidationHelp from '../components/ui/ValidationHelp';
import ValidationResults from '../components/ui/ValidationResults';

import { downloadTemplate } from '../utils/downloadS3';

import { templateFile } from '../config';
import content from '../config/content';

const ValidatePage = () => {
  // context
  const { fetch, user, setUploadProgress } = React.useContext(FetchContext);
  const { darkMode } = React.useContext(ThemeContext);

  // state
  const [downloadUrl, setDownloadUrl] = React.useState(null);

  // hooks
  const {
    formValidation,
    status,
    fileToUpload,
    handleFileChange,
    handleUpload,
    validationResults,
    runUID,
    resetAnalysis,
    setValidationResults,
  } = useFetchValidate(fetch, setUploadProgress);
  const { etlStatus, handleRunETL, resetETL } = useFetchETL(fetch);

  // function
  const fetchTemplateURL = async () => {
    const url = await downloadTemplate(templateFile(user.group));
    setDownloadUrl(url);
  };

  const handleReset = level => {
    switch (level) {
      case 'clear-analysis':
        setValidationResults(null);
        break;
      default:
        resetAnalysis();
        resetETL();
        break;
    }
  };

  // lifecycle
  React.useEffect(() => {
    fetchTemplateURL();
    // eslint-disable-next-line
  }, [user]);

  // render
  return (
    <Container>
      <Grid>
        <Grid.Row columns={2} stretched style={{ paddingTop: '2rem' }}>
          <Grid.Column>
            <ValidationHelp inverted={darkMode} />
          </Grid.Column>
          <Grid.Column>
            <Segment inverted={darkMode}>
              <Header inverted={darkMode} as="h4">
                {content.validation.header}
                <Header.Subheader>
                  {content.validation.subheader}
                </Header.Subheader>
              </Header>
              <LoadingMessage status={status} />
              <ValidationForm
                status={status}
                formValidation={formValidation}
                fileToUpload={fileToUpload}
                runUID={runUID}
                handleFileChange={handleFileChange}
                handleUpload={handleUpload}
              />
            </Segment>
            <TemplateDownload inverted={darkMode} url={downloadUrl} />
          </Grid.Column>
        </Grid.Row>
        <ValidationResults results={validationResults} />
        <RunETL
          results={validationResults}
          uid={runUID}
          status={etlStatus}
          handleClick={handleRunETL}
          handleReset={handleReset}
        />
      </Grid>
    </Container>
  );
};

export default ValidatePage;
