import React from 'react';
import { Container, Segment } from 'semantic-ui-react';

import { ThemeContext } from '../components/context/Theme';

import HelpInfo from '../components/ui/HelpInfo';

const Help = () => {
  const { darkMode } = React.useContext(ThemeContext);
  return (
    <Container>
      <Segment inverted={darkMode}>
        <h2>NG911 Help Document</h2>
        <HelpInfo />
      </Segment>
    </Container>
  );
};

export default Help;
