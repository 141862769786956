import React from 'react';

import { ThemeContext } from '../components/context/Theme';

// import Welcome from '../components/ui/Welcome';
import Welcome from '../components/ui/WelcomeHero';

const Home = () => {
  const { darkMode } = React.useContext(ThemeContext);
  return <Welcome inverted={darkMode} />;
};

export default Home;
