import _ from 'lodash';
import moment from 'moment';
import prettyBytes from 'pretty-bytes';
import React from 'react';
import { Icon, Table } from 'semantic-ui-react';

const S3ItemsTable = ({
  objects,
  darkMode,
  handleDownloadFile,
  downloadUrl,
}: {
  objects: Array<{
    key: string;
    eTag: string;
    lastModified: Date;
    size: number;
  }>;
  darkMode: boolean;
  handleDownloadFile: (key: string) => void;
  downloadUrl: string;
}) => {
  // ref
  const downloadEl = React.useRef(null);

  // lifecycle
  React.useEffect(() => {
    if (downloadUrl) {
      // tslint:disable-next-line
      console.info(`Downloading: ${downloadUrl}`);
      downloadEl.current.click();
    }
  }, [downloadUrl]);

  return (
    <>
      <Table celled striped selectable inverted={darkMode}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Last Modified</Table.HeaderCell>
            <Table.HeaderCell>Size</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {_.orderBy(objects, ['lastModified'], ['desc', 'asc']).map(o => {
            return (
              <Table.Row key={o.eTag}>
                <Table.Cell
                  onClick={() => handleDownloadFile(o.key)}
                  style={{ cursor: 'pointer' }}
                >
                  <Icon name="cloud download" /> {o.key}
                </Table.Cell>
                <Table.Cell>
                  {moment(o.lastModified).format('MMMM Do YYYY, h:mm:ss a')}
                </Table.Cell>
                <Table.Cell>{prettyBytes(o.size)}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {/* Hidden link used to programatically download file */}
      <a
        href={downloadUrl}
        ref={downloadEl}
        download
        style={{ display: 'none' }}
      >
        Download S3 Object
      </a>
    </>
  );
};

export default S3ItemsTable;
