export const api = {
  base: 'https://uin8o8a421.execute-api.us-east-1.amazonaws.com/v1',
  endpoints: {
    configCurrent: '/config/current',
    runValidate: '/run/validate',
    statusValidate: '/status/validate',
    runLoad: '/run/load',
  },
  statusInterval: 5000,
};

export const templateFile = (group: string) => {
  return `${group}.gdb.zip`;
};

const config = {
  GA: {
    id: 'UA-162788603-1',
  },
};

export default config;
