import React from 'react';
import { Icon, Message } from 'semantic-ui-react';
import content from '../../config/content';
import SentryFeedback from './SentryFeedback';

const { messages } = content.validation;

const LoadingMessage = ({
  status,
}: {
  status: { status: string; info: any };
}) => {
  if (status.status === 'error') {
    return (
      <Message icon negative>
        <Icon name="exclamation" />
        <Message.Content>{messages.error.message}</Message.Content>
        <SentryFeedback />
      </Message>
    );
  }

  if (status.status === 'working') {
    return (
      <Message icon info>
        {status.info === 'processing' ? (
          <Icon name="circle notched" loading />
        ) : (
          <Icon name="upload" />
        )}
        <Message.Content>
          <div style={{ paddingBottom: '0.5rem' }}>
            {messages[status.info].message}
          </div>{' '}
          <div>
            <strong>Important!</strong> Please don't refresh your browser or
            navigate away from this page!
          </div>
        </Message.Content>
      </Message>
    );
  }

  return null;
};

export default LoadingMessage;
