import * as React from 'react';
import { sentryCapture } from '../config/sentry';
import { downloadObject, listObjects } from '../utils/downloadS3';

const useFetchDownload = () => {
  // state
  const [status, setStatus] = React.useState({ status: 'loading', info: null });
  const [downloadUrl, setDownloadUrl] = React.useState(null);
  const [objects, setObjects] = React.useState(null);

  // functions
  const fetchObjects = async () => {
    try {
      const s3Objects = await listObjects();
      setObjects(s3Objects);
      setStatus({ status: 'ready', info: null });
    } catch (error) {
      sentryCapture(error);
      setStatus({ status: 'error', info: null });
    }
  };

  const downloadFile = async (key: string) => {
    try {
      setStatus({ status: 'downloading', info: null });
      const url = await downloadObject(key);
      setDownloadUrl(url);
      setStatus({ status: 'ready', info: null });
    } catch (error) {
      sentryCapture(error);
      setStatus({ status: 'error', info: null });
    }
  };

  const reset = () => {
    setStatus({ status: 'loading', info: null });
    setObjects(null);
  };

  // lifecycle
  React.useEffect(() => {
    fetchObjects();
    return reset;
  }, []);

  return {
    status,
    objects,
    downloadUrl,
    downloadFile,
  };
};

export default useFetchDownload;
