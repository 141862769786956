import * as React from 'react';
import { sentryCapture } from '../config/sentry';

const useFetchETL = fetch => {
  // state
  const [status, setStatus] = React.useState({ status: 'ready', info: null });
  const [results, setResults] = React.useState(null);

  // functions
  const handleRunETL = async (uid: string) => {
    setStatus({ status: 'loading', info: null });
    try {
      const resp = await fetch.RunETL({
        uid,
      });
      setResults(resp);
      setStatus({ status: 'success', info: null });
    } catch (error) {
      sentryCapture(error);
      // tslint:disable-next-line
      console.error(error);
      setStatus({ status: 'error', info: null });
    }
  };

  const resetETL = () => {
    setStatus({ status: 'ready', info: null });
    setResults(null);
  };

  return {
    etlStatus: status,
    etlResp: results,
    handleRunETL,
    resetETL,
  };
};

export default useFetchETL;
