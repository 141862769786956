import { CognitoUserSession } from 'amazon-cognito-identity-js';
import axios, { AxiosInstance } from 'axios';
import _ from 'lodash';

import { api } from '../config';
import { sentryCapture } from '../config/sentry';

interface ISession {
  getIdToken: () => {
    getJwtToken: () => string;
    decodePayload: () => {
      email?: string;
    };
  };
}

export interface IUser {
  email: string;
  username: string;
  group: string;
}

interface IRunResp {
  success: boolean;
  data: {
    uid: string;
  };
}

interface IStatusResp {
  success: boolean;
  data: {
    status: string;
    report: object;
    error?: Error;
  };
}

interface IValidationResp {
  run: IRunResp;
  result: IStatusResp;
  uid: string;
}

export const getUser = (session: CognitoUserSession) => {
  const payload = session.getIdToken().decodePayload();
  return {
    email: _.get(payload, 'email', null),
    group: _.get(payload, 'cognito:groups', [null])[0],
    sub: _.get(payload, 'sub', null),
    username: _.get(payload, 'cognito:username', null),
  };
};

export class Fetch {
  private session: ISession;
  private instance: AxiosInstance;
  private token: string;
  private user: {
    email: string;
    group: string;
    username: string;
  };

  constructor(session: ISession, user: IUser) {
    // extract info from session
    const id = session.getIdToken();

    // set data for requests
    this.session = session;
    this.token = id.getJwtToken();
    this.user = user;
    this.instance = axios.create({
      baseURL: api.base,
      headers: { Authorization: this.token },
    });
  }

  public async RunValidation(params: {
    s3Response: { info: { key: any } };
  }): Promise<IValidationResp> {
    const run = await this.instance.post(api.endpoints.runValidate, {
      county: this.user.group,
      key: params.s3Response.info.key,
    });

    const result = await this.CheckValidationStatusInvertal(run.data);

    return { run: run.data, result, uid: run.data.data.uid };
  }

  public async RunETL(params: { uid: string }): Promise<IValidationResp> {
    // return;
    const etl = await this.instance.post(api.endpoints.runLoad, {
      uid: params.uid,
    });

    return etl.data;
  }

  private async CheckValidationStatusInvertal(
    run: IRunResp,
  ): Promise<IStatusResp> {
    return new Promise((resolve, reject) => {
      try {
        const checkInterval = setInterval(async () => {
          const check = await this.CheckValidationStatus(run.data.uid);
          if (check.data.status === 'failed' || check.data.status === 'error') {
            clearInterval(checkInterval);
            // we could reject here, but we're going to resolve and display error message
            // reject(new Error(JSON.stringify(check)));
            resolve(check);
          }
          if (check.data.status === 'success') {
            clearInterval(checkInterval);
            resolve(check);
          }
        }, api.statusInterval);
      } catch (error) {
        sentryCapture(error);
        reject(error);
      }
    });
  }

  private async CheckValidationStatus(uid: string): Promise<IStatusResp> {
    try {
      const status = await this.instance.post(api.endpoints.statusValidate, {
        uid,
      });
      return status.data;
    } catch (error) {
      sentryCapture(error);
      return { data: { status: 'error', error, report: null }, success: false };
    }
  }
}
