import content from './content';

const cognito = {
  Auth: {
    identityPoolId: process.env.REACT_APP_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_WEB_ID,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_BUCKET,
      region: process.env.REACT_APP_REGION,
    },
  },
};

// https://github.com/aws-amplify/amplify-js/blob/master/packages/aws-amplify-react/src/Amplify-UI/Amplify-UI-Theme.jsx
export const CognitoTheme = {
  button: {
    backgroundColor: '#2185d0',
    borderRadius: '.28571429rem',
    color: '#fff',
  },
};

// Set Text
export const CognitoLabels = {
  en: {
    'Sign in to your account': `Sign in to the ${content.title}`,
  },
};

export default cognito;
