import React, { useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import content from '../../config/content';

const styles = {
  accordTitle: {
    marginTop: '4rem',
    fontWeight: 'bold',
    fontSize: 'medium',
    display: 'block',
  },
  accordCenter: {
    textAlign: 'center',
  } as React.CSSProperties,
};
const HelpInfo = () => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };
  return (
    <Accordion inverted style={{ display: 'block', margin: '0 auto' }}>
      <Accordion.Title
        style={styles.accordTitle}
        active={activeIndex === 0}
        index={0}
        onClick={handleClick}
      >
        {content.helpPage.HelpPageitem.content1}
        <Icon name="dropdown" />
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 0}>
        <h5>{content.HelpPageContent1.Header1}</h5>
        <ul>
          <li>
            <p>{content.HelpPageContent1.HelpAccordItems.content1}</p>
          </li>
        </ul>
        <h5>{content.HelpPageContent1.Header2}</h5>
        <ul>
          <li>
            <p>{content.HelpPageContent1.HelpAccordItems.content2}</p>
          </li>
          <li>
            <p>{content.HelpPageContent1.HelpAccordItems.content3}</p>
          </li>
          <li>
            <p>{content.HelpPageContent1.HelpAccordItems.content4}</p>
          </li>
          <li>
            <p>{content.HelpPageContent1.HelpAccordItems.content5}</p>
          </li>
        </ul>

        <h5>{content.HelpPageContent1.HelpAccordItems.content6}</h5>
      </Accordion.Content>

      <Accordion.Title
        style={styles.accordTitle}
        active={activeIndex === 1}
        index={1}
        onClick={handleClick}
      >
        {content.helpPage.HelpPageitem.content3}
        <Icon name="dropdown" />
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 1}>
        <h5>{content.HelpPageContent3.HelpAccordItems.content1}</h5>
        <h5>{content.HelpPageContent3.HelpAccordItems.content2}</h5>
        <ul>
          <li>
            <p>{content.HelpPageContent3.HelpAccordItems.content3}</p>
          </li>
        </ul>
        <h5>{content.HelpPageContent3.HelpAccordItems.content4}</h5>
        <ul>
          <li>
            <p>{content.HelpPageContent3.HelpAccordItems.content5}</p>
          </li>
          <li>
            <p>{content.HelpPageContent3.HelpAccordItems.content6}</p>
          </li>
        </ul>
        <h5>{content.HelpPageContent3.HelpAccordItems.content7}</h5>
        <ul>
          <li>
            <p>{content.HelpPageContent3.HelpAccordItems.content8}</p>
          </li>
          <li>
            <p>{content.HelpPageContent3.HelpAccordItems.content9}</p>
          </li>
        </ul>
      </Accordion.Content>

      <Accordion.Title
        style={styles.accordTitle}
        active={activeIndex === 2}
        index={2}
        onClick={handleClick}
      >
        {content.helpPage.HelpPageitem.content4}
        <Icon name="dropdown" />
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 2}>
        <h5>{content.HelpPageContent4.content1}</h5>
        <h5>{content.HelpPageContent4.content2}</h5>
        <li>{content.HelpPageContent4.content3}</li>
      </Accordion.Content>

      <Accordion.Title
        style={styles.accordTitle}
        active={activeIndex === 3}
        index={3}
        onClick={handleClick}
      >
        {content.helpPage.HelpPageitem.content5}
        <Icon name="dropdown" />
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 3}>
        <h5>{content.HelpPageContent5.content1}</h5>
        <p>
          <a href={content.HelpPageContent5.link1}>
            <i>NENA Model</i>
          </a>
        </p>
        <p>
          <a href={content.HelpPageContent5.link2}>
            <i>PEMA NG-9-1-1 Info Page</i>
          </a>
        </p>
        <p>
          <a href={content.HelpPageContent5.link3}>
            <i>Pennsylvania Specific items incorporated with NENA</i>
          </a>
        </p>
      </Accordion.Content>

      <Accordion.Title
        style={styles.accordTitle}
        active={activeIndex === 4}
        index={4}
        onClick={handleClick}
      >
        {content.HelpPageContent6.Header1}
        <Icon name="dropdown" />
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 4}>
        <h5>{content.HelpPageContent6.HelpAccordItems.content1}</h5>
        <ul>
          <li>{content.HelpPageContent6.HelpAccordItems.content1Sub1}</li>
          <li>{content.HelpPageContent6.HelpAccordItems.content1Sub2}</li>
          <li>{content.HelpPageContent6.HelpAccordItems.content1Sub3}</li>
        </ul>
        <h5>{content.HelpPageContent6.HelpAccordItems.content2}</h5>
        <ul>
          <li>{content.HelpPageContent6.HelpAccordItems.content2Sub1}</li>
        </ul>
        <h5>{content.HelpPageContent6.HelpAccordItems.content3}</h5>
        <ul>
          <li>{content.HelpPageContent6.HelpAccordItems.content3Sub1}</li>
        </ul>
        <h5>{content.HelpPageContent6.HelpAccordItems.content4}</h5>
      </Accordion.Content>

      <Accordion.Title
        style={styles.accordTitle}
        active={activeIndex === 5}
        index={5}
        onClick={handleClick}
      >
        {content.HelpPageContent7.Header1}
        <Icon name="dropdown" />
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 5}>
        <h5>{content.HelpPageContent7.HelpAccordItems.content1}</h5>
        <ul>
          <li>{content.HelpPageContent7.HelpAccordItems.content1Sub1}</li>
        </ul>
        <h5>{content.HelpPageContent7.HelpAccordItems.content2}</h5>
        <ul>
          <li>{content.HelpPageContent7.HelpAccordItems.content2Sub1}</li>
        </ul>
        <h5>{content.HelpPageContent7.HelpAccordItems.content3}</h5>
        <ul>
          <li>{content.HelpPageContent7.HelpAccordItems.content3Sub1}</li>
        </ul>
      </Accordion.Content>
    </Accordion>
  );
};

export default HelpInfo;
