import React from 'react';
import { Segment } from 'semantic-ui-react';

import content from '../../config/content';

const ValidationHelp = ({ inverted }: { inverted: boolean }) => {
  return (
    <Segment inverted={inverted}>
      <h3>{content.Validate.header1}</h3>
      <p>
        <li>{content.Validate.ValidateHelpInfo.content2}</li>
      </p>
      <p>
        <li>{content.Validate.ValidateHelpInfo.content3}</li>
      </p>
      <p>
        <li>{content.Validate.ValidateHelpInfo.content4}</li>
      </p>
      <h3>{content.Validate.header2}</h3>
      <p>
        <li>{content.Validate.ValidateHelpInfo.content6}</li>
      </p>
    </Segment>
  );
};

export default ValidationHelp;
