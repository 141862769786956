import _ from 'lodash';
import React from 'react';
import { Grid, Icon, Message, Segment, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { IResults, IResultsDisplay } from '../../interface';
import { ThemeContext } from '../context/Theme';
import SentryFeedback from './SentryFeedback';

const MsgWrap = styled.div`
  margin: 0 3rem;
`;

const ValidationMsg = ({
  positive,
  warning,
  error,
  content,
}: {
  positive?: boolean;
  warning?: boolean;
  error?: boolean;
  content?: string;
}) => {
  return (
    <MsgWrap>
      <Message icon positive={positive} warning={warning} error={error}>
        <Icon name="database" />
        <Message.Content>
          <Message.Header>
            {error ? 'Analysis Error' : 'Analysis Complete'}
          </Message.Header>
          {content}
        </Message.Content>
        <SentryFeedback />
      </Message>
    </MsgWrap>
  );
};

const ValidationTable = ({
  display,
  hidden,
  inverted,
}: {
  hidden?: boolean;
  inverted: boolean;
  display: IResultsDisplay[];
}) => {
  if (hidden) {
    return null;
  }

  return (
    <Table striped inverted={inverted} selectable celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Feature Class</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Note</Table.HeaderCell>
          <Table.HeaderCell>Details</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {_.map(display, d => {
          return (
            <Table.Row verticalAlign="top" key={d.name}>
              <Table.Cell>{d.name}</Table.Cell>
              <Table.Cell>{_.upperCase(d.status)}</Table.Cell>
              <Table.Cell>{d.message}</Table.Cell>
              <Table.Cell verticalAlign="top">
                {_.map(d.fieldIssues, f => {
                  return (
                    <span key={f.name}>
                      <span>{`${_.upperCase(f.name)}: ${f.status}`}</span>
                      <br />
                    </span>
                  );
                })}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};
const ValidationResults = ({ results }: { results: IResults | null }) => {
  const { darkMode } = React.useContext(ThemeContext);
  if (!results) {
    return null;
  }

  return (
    <Grid.Row columns={1}>
      <Grid.Column>
        <Segment inverted={darkMode}>
          <ValidationMsg
            positive={results.status === 'valid'}
            warning={results.status === 'invalid'}
            error={results.status === 'error'}
            content={results.message}
          />
          <ValidationTable
            inverted={darkMode}
            hidden={results.status === 'error'}
            display={results.display}
          />
        </Segment>
      </Grid.Column>
    </Grid.Row>
  );
};

export default ValidationResults;
