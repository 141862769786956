import Amplify, { I18n } from 'aws-amplify';
import {
  ConfirmSignIn,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  VerifyContact,
  withAuthenticator,
} from 'aws-amplify-react';
import React from 'react';

import { ThemeProvider } from './context/Theme';

import Router from '../routes';
import ErrorBoundary from './ErrorBoundary';

import cognito, { CognitoLabels, CognitoTheme } from '../config/cognito';

// Init Amplify
Amplify.configure(cognito);

// Set labels
I18n.setLanguage('en');
I18n.putVocabularies(CognitoLabels);

// render app
const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </ErrorBoundary>
  );
};

// wrap in cognito auth
// export default withAuthenticator(App, {
//   authenticatorComponents: [
//     <SignIn />,
//     <ConfirmSignIn />,
//     <RequireNewPassword />,
//     <VerifyContact />,
//   ],
//   includeGreetings: false,
//   theme: CognitoTheme,
// });

export default withAuthenticator(
  App,
  false,
  [
    <SignIn />,
    <ConfirmSignIn />,
    <RequireNewPassword />,
    <VerifyContact />,
    <ForgotPassword />,
  ],
  CognitoTheme,
);
