import React from 'react';

import { Route, Router, Switch } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';

import Layout from '../components/Layout';

import { history } from '../config/ga';
import Download from './Download';
import ETL from './ETL';
import Help from './Help';
import Home from './Home';
import NotFound from './NotFound';
import Settings from './Settings';
import Validate from './Validate';

const AppRoutes = () => {
  const animate = useSpring({
    config: { duration: 1000 },
    from: { opacity: 0 },
    opacity: 1,
  });

  return (
    <Layout>
      <animated.div style={animate}>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/data-conversion" exact component={Validate} />
          <Route path="/etl" exact component={ETL} />
          <Route path="/data-download" exact component={Download} />
          <Route path="/settings" exact component={Settings} />
          <Route path="/help" exact component={Help} />
          <Route component={NotFound} />
        </Switch>
      </animated.div>
    </Layout>
  );
};

const AppRouter = () => {
  return (
    <Router history={history}>
      <AppRoutes />
    </Router>
  );
};

export default AppRouter;
