import _ from 'lodash';
import React from 'react';
import { Form, Grid } from 'semantic-ui-react';

import { ThemeContext } from '../context/Theme';

const ValidationForm = ({
  status,
  formValidation,
  fileToUpload,
  runUID,
  handleFileChange,
  handleUpload,
}) => {
  const { darkMode } = React.useContext(ThemeContext);
  // ref
  const inputEl = React.useRef(null);

  return (
    <>
      <Grid padded columns={3}>
        <Grid.Column width={2} />
        <Grid.Column width={12}>
          <Form inverted={darkMode}>
            <Form.Field>
              <label>Select your zip file:</label>
              <Form.Button
                error={formValidation.chooseFile}
                inverted={darkMode}
                content={fileToUpload ? `${fileToUpload.name}` : 'Choose File'}
                disabled={status.status === 'working' || !_.isEmpty(runUID)}
                icon="file archive"
                onClick={() => inputEl.current.click()}
                fluid
              />
              <input
                ref={inputEl}
                type="file"
                accept="zip"
                hidden
                onChange={handleFileChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Upload your zip file:</label>
              <Form.Button
                onClick={handleUpload}
                size="large"
                primary
                inverted={darkMode}
                icon="upload"
                disabled={!fileToUpload || status.status === 'working'}
                fluid
                content="Upload"
              />
            </Form.Field>
          </Form>
        </Grid.Column>
        <Grid.Column width={2} />
      </Grid>
    </>
  );
};

export default ValidationForm;
