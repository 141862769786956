import _ from 'lodash';

const filterFieldIssues = fields => {
  return _.chain(fields)
    .map(f => {
      if (f.status !== 'OK') {
        return f;
      }

      return null;
    })
    .flattenDeep()
    .compact()
    .value();
};

const checkIsValid = features => {
  // analyze results and format for UI
  const display = _.chain(features)
    .map(fc => {
      const fields = filterFieldIssues(fc.fields);

      // if valid
      if (fc.exists && _.isEmpty(fields)) {
        return {
          name: fc.name,
          status: 'ok',
          message: 'Validation passed with no issues.',
        };
      }

      // fc missing
      if (!fc.exists) {
        return {
          name: fc.name,
          status: 'ok',
          message: 'Feature Class Missing.',
        };
      }

      // field issues
      if (!_.isEmpty(fields)) {
        return {
          name: fc.name,
          status: 'field-issue',
          message: 'There is an issue with the current fields.',
          fieldIssues: fields,
        };
      }

      return null;
    })
    .compact()
    .flattenDeep()
    .value();

  // check if all FCs are valid
  const valid = _.chain(display)
    .filter(d => {
      // @tsignore
      return d.status !== 'ok';
      // return true;
    })
    .isEmpty()
    .value();

  // return results
  return {
    status: valid ? 'valid' : 'invalid',
    display,
    message: valid
      ? 'Submitted GDB is valid! Scroll down to run ETL process.'
      : 'Submitted GDB is not valid!',
  };
};

const formatValidation = data => {
  // first, make sure we have results
  if (
    data.status !== 'success' ||
    !_.has(data.report, 'Features') ||
    _.isEmpty(data.report.Features)
  ) {
    return {
      status: 'error',
      display: null,
      message:
        'Error running analysis, please check the format of your zip file.',
    };
  }

  // second, check if all fcs are valid
  const validationResults = checkIsValid(data.report.Features);

  return validationResults;
};

export default formatValidation;
