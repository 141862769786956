/* eslint-disable no-console */
import { createBrowserHistory } from 'history';
import _ from 'lodash';
import ReactGA from 'react-ga';

import config from './index';

// init
const gaInit = () => {
  try {
    return ReactGA.initialize(config.GA.id);
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
};

// capture pageviews
export const history = createBrowserHistory();
history.listen(location => {
  try {
    ReactGA.pageview(location.pathname);
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
});

// capture user
export const setGAUser = user => {
  try {
    return ReactGA.set({
      userId: user.sub,
    });
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
};

export const captureErrorGA = err => {
  try {
    // tslint:disable-next-line: no-console
    console.log('Error', err.message);
    const description = _.has(err, 'message')
      ? err.message
      : 'landing page error - check Sentry';
    // tslint:disable-next-line: no-console
    console.log(description);
    ReactGA.exception({
      description,
    });
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
};

export default gaInit;
